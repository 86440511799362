import _ from 'lodash'
import React from 'react'
import VigilanceHubForm from '@redant/mhra-form-ui-library'
import { useSelector } from 'react-redux'
import Skeleton from '@material-ui/lab/Skeleton'
import { baseUrl } from '../../../../../digitalStoreSdk'
import { styled } from '@material-ui/core/styles'
import { translations, defaultTranslations } from '../../../../../config'

// Selectors
import { selectors as authSelectors } from '../../../../../store/modules/auth'
import { selectors as themeSelectors } from '../../../../../store/modules/themes'
import { selectors as reportDetailsSelectors } from '../../../../../store/modules/reportDetails'
import { selectors as formViewSelectors } from '../../../../../store/modules/formViews'
import modalService from '../../../../../services/modalService'

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center'
}))

const ReportContainer = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  width: '100%',
  maxWidth: 720
}))

const SkeletonContainer = styled(ReportContainer)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  paddingTop: theme.spacing(4),
  '& > span': {
    marginBottom: theme.spacing(1)
  }
}))

const SkeletonSection = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(4),
  '& > :first-child': {
    marginBottom: theme.spacing(2)
  }
 }))

const SkeletonFieldContainer = styled('div')(({ theme }) => ({
 marginBottom: theme.spacing(2)
}))

const SkeletonField = (props = {}) => {
  const { width: fieldWidth = '100%' } = props
  return (
    <SkeletonFieldContainer>
      <Skeleton variant={'text'} width={'50%'} />
      <Skeleton variant={'rect'} width={fieldWidth} height={44} />
    </SkeletonFieldContainer>
  )
}

const ReportLoader = () => {
  return (
    <Container>
      <SkeletonContainer>
        <SkeletonSection>
          <Skeleton variant={'rect'} height={50} />
          <SkeletonField />
          <SkeletonField width={'75%'} />
          <SkeletonField />
          <SkeletonField />
          <SkeletonField width={'60%'} />
        </SkeletonSection>
        <SkeletonSection>
          <Skeleton variant={'rect'} height={50} />
          <SkeletonField />
          <SkeletonField width={'60%'} />
        </SkeletonSection>
      </SkeletonContainer>
    </Container>
  )
}

const ReportForm = (props) => {
  const {
    onFormStateChange,
    onSuccess,
    onError,
    onSaveDraftSuccess,
    onSaveDraftError,
    initialValues: passedInitialValues,
    organisationDetails,
    productDetails,
    isReadOnly,
    isNewReport,
    anonymisedReport
  } = props

  const report = useSelector(reportDetailsSelectors.getReport) 
  const theme = useSelector(themeSelectors.getThemeForNewReportBetaForms)
  const auth = useSelector(authSelectors.getFormAuth)
  const organisationId = _.get(useSelector(authSelectors.getSelectedOrganisation), 'id')
  const userDetails = useSelector(authSelectors.getCurrentUser)
  const formViewId = _.get(report, 'formViewVersion.formViewId', useSelector(formViewSelectors.getSelectedFormViewId))
  const formSchemaId =  _.get(report, 'formSchemaId', useSelector(formViewSelectors.getSelectedFormSchemaId))
  const selectedSchemaName = _.get(report, 'formSchema.name', useSelector(formViewSelectors.getSelectedSchemaName))
  const selectedFormSchemaName = _.get(report, 'formSchema.name', useSelector(formViewSelectors.getSelectedFormSchemaName))
  const formSchemaName = selectedFormSchemaName || selectedSchemaName
  const reportTarget = {}
  const source = {}
  const sourceId = 'd97b3f5a-04ee-4ed5-a527-d27b1ce38657'
  const reportDetails = useSelector(reportDetailsSelectors.getDetails)
  const initialValues = anonymisedReport || reportDetails || passedInitialValues  
  const reportId = _.get(report, 'id')
  const extReportId = _.get(report, 'extReportId')
  const enableDrafts = true
  const keepDirtyOnReinitialize = true

  const requestCloseRepeatable = (cb) => (...args) =>
    modalService.action({
      title: translations('Warning'),
      text: translations('Repeatable Requested Close Warning Message'),
      actions: [
        {
          text: translations('Cancel')
        },
        {
          text: translations('Discard changes'),
          onClick: () => cb(...args)
        }
      ]
    })

  const isLoadingReport = useSelector(reportDetailsSelectors.getIsLoading)

  if (isLoadingReport) {
    return <ReportLoader />
  }

  return (
    <Container>
      <ReportContainer>
        {(formViewId || formSchemaName) &&
          <VigilanceHubForm
            theme={theme}
            auth={auth}
            baseUrl={baseUrl}
            organisationId={organisationId}
            userDetails={userDetails}
            formViewId={formViewId}
            formSchemaId={formSchemaId}
            formSchemaName={formSchemaName}
            reportTarget={reportTarget}
            initialValues={initialValues}
            reportId={reportId}
            sourceId={sourceId}
            extReportId={extReportId}
            enableDrafts={enableDrafts}
            keepDirtyOnReinitialize={keepDirtyOnReinitialize}
            onFormStateChange={onFormStateChange}
            onSuccess={onSuccess}
            onError={onError}
            onSaveDraftSuccess={onSaveDraftSuccess}
            onSaveDraftError={onSaveDraftError}
            singlePageDisplay={true}
            organisationDetails={organisationDetails}
            productDetails={productDetails}
            readOnly={isReadOnly}
            isNewReport={isNewReport}
            defaultTranslations={defaultTranslations}
            requestCloseRepeatable={requestCloseRepeatable}
          />
        }
        {(!formViewId && !formSchemaName) && <p>{translations('New Report Beta - Loading')}</p>}
      </ReportContainer>
    </Container>
  )

}

export default ReportForm
