import * as constants from './constants'
import reducers from './reducers'
import moment from 'moment'

const defaultState = {
  formViews: {
    status: undefined,
    error: undefined,
    entities: [],
    filtersById: {},
    byId: {},
    bySchema: {},
    byCategory: {},
    activeFormViewFilters: { versionId: undefined }
  },
  filters: {
    category: undefined,
    formSchema: undefined,
    public: undefined,
    publish: undefined
  },
  defaultFilters: {
    dateFrom: moment().subtract(1, 'week').format('MM/DD/YYYY'),
    noSummary: true,
    size: 50,
    page: 1
  },
  reports: {
    total: 0,
    resultById: {},
    filtersById: {},
    entities: [],
    byReporterLatest: {},
    status: undefined,
    error: undefined
  },
  reporters: {
    status: undefined,
    error: undefined,
    entities: [],
    byId: {},
    filtersById: {},
    query: undefined,
    searchType: 'name'
  },
  followUps: {
    status: undefined,
    error: undefined,
    entities: [],
    byId: {},
    byReporter: {},
    filtersById: {}
  },
  cohorts: {
    status: undefined,
    error: undefined,
    entities: [],
    byId: {}
  },
  editor: {
  },
  formSchemas: {}
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.FETCH_FORM_VIEWS:
      return reducers.fetchFormViews(state, action)
    case constants.FETCH_FORMVIEW_BY_ID:
      return reducers.fetchFormViewById(state, action)
    case constants.CHANGE_REPORTS_FILTERS:
      return reducers.changeReportFilters(state, action)
    case constants.CHANGE_DEFAULT_FILTERS:
      return reducers.changeDefaultFilters(state, action)
    case constants.SEARCH_REPORTS_FRESH:
      return reducers.fetchReportByFormViewIds(state, action)
    case constants.SEARCH_REPORTS_NEXT:
      return reducers.searchReportsNext(state, action)
    case constants.SEARCH_FORMVIEW_REPORTERS_FRESH:
      return reducers.fetchReporters(state, action)
    case constants.SEARCH_FORMVIEW_REPORTERS_NEXT:
      return reducers.searchReportersNext(state, action)
    case constants.CHANGE_FORMVIEW_REPORTERS_QUERY:
      return reducers.changeReportersQuery(state, action)
    case constants.UPDATE_FORMVIEW_REPORTERS_FILTERS:
      return reducers.changeReportersFilters(state, action)
    case constants.UPDATE_FOLLOWUP_FILTERS:
      return reducers.updateFollowUpFilters(state, action)
    case constants.SEARCH_FOLLOWUP_FRESH:
      return reducers.searchFollowUpFresh(state, action)
    case constants.SET_INITIAL_EDITOR_VALUES:
      return reducers.setInitialEditorValues(state, action)
    case constants.UPDATE_FORM_VIEW_FILTERS:
      return reducers.updateFormViewFilters(state, action)
    case constants.CREATE_FOLLOW_UP:
      return reducers.createFollowUp(state, action)
    case constants.UPDATE_FOLLOW_UP:
      return reducers.updateFollowUp(state, action)
    case constants.FETCH_FOLLOW_UP_REPORT_CONFIGURATION:
      return reducers.fetchFollowUpFormView(state, action)
    case constants.SEARCH_COHORTS_FRESH:
      return reducers.searchCohortsFresh(state, action)
    case constants.FETCH_FORMVIEW_REPORTER_FOLLOWUPS:
      return reducers.fetchFormViewReporterFollowUps(state, action)
    case constants.CREATE_INDIVIDUAL_FOLLOW_UP:
      return reducers.createIndividualFollowUp(state, action)
    case constants.FETCH_LATEST_REPORT_VERSION_FOR_REPORTER:
      return reducers.fetchLatestReportVersionForReporter(state, action)
    case constants.FETCH_REPORT_FOR_ID:
    default:
      return state
  }
}
