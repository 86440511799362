import _ from 'lodash'
import FormSchemaLibrary from '@redant/mhra-form-schema-library'
import { SUCCESS, PENDING, FAIL } from '../../middleware/redux-promise'
import { promiseReducer } from '../../util'
class ReportConfigurationReducers {
  fetchFormViews = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchFormViewsPending({ state, action })
      case SUCCESS:
        return this.fetchFormViewsSuccess({ state, action })
      case FAIL:
        return this.fetchFormViewsFail({ state, action })
      default:
        return state
    }
  }

  fetchFormViewsPending = ({ state, isSilent }) => {
    const currentStatus = _.get(state, 'formViews.status', PENDING)
    return {
      ...state,
      formViews: {
        ...state.formViews,
        error: undefined,
        status: isSilent ? currentStatus : PENDING
      }
    }
  }

  fetchFormViewsSuccess = ({ state, action }) => {
    const { result } = action
    return {
      ...state,
      formViews: {
        entities: _.map(result, 'id'),
        byId: this._normalizeResults({ result }),
        error: undefined,
        status: SUCCESS,
        activeFormViewFilters: state.formViews.activeFormViewFilters
      },
      formSchemas: {
        ...state.formSchemas,
        ...this._getFormSchemas(result)
      },
    }
  }

  fetchFormViewsFail = ({ state, action }) => {
    const { error } = action
    return {
      ...state,
      formViews: {
        ...state.formViews,
        error: error.message,
        status: FAIL
      }
    }
  }

  updateFormViewFilters = (state, action) => {
    const oldFilters = _.get(state, 'formViews.activeFormViewFilters')
    const newFilters = _.get(action, 'payload')
    return {
      ...state,
      formViews: {
        ...state.formViews,
        activeFormViewFilters: {
          ...oldFilters, ...newFilters
        }
      }
    }
  }

  fetchFormViewById = (state, action) => {
    const isSilent = _.get(action, 'payload.isSilent', false)
    switch (action.status) {
      case PENDING:
        return this.fetchFormViewsPending({ state, action, isSilent })
      case SUCCESS:
        return this.fetchFormViewByIdSuccess({ state, action })
      case FAIL:
        return this.fetchFormViewsFail({ state, action })
      default:
        return state
    }
  }

  fetchFormViewByIdSuccess = ({ state, action }) => {
    const { result } = action

    return {
      ...state,
      formViews: {
        entities: _.union([...state.formViews.entities, result.id]),
        byId: {
          ...state.formViews.byId,
          [result.id]: result
        },
        error: undefined,
        status: SUCCESS
      },
      formSchemas: {
        ...state.formSchemas,
        ...this._getFormSchemas(result)
      }
    }
  }

  fetchReportByFormViewIds = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.fetchReportByFormViewIdsPending({ state, action })
      case SUCCESS:
        return this.fetchReportByFormViewIdsSuccess({ state, action })
      case FAIL:
        return this.fetchReportByFormViewIdsFail({ state, action })
      default:
        return state
    }
  }

  fetchReportByFormViewIdsPending = ({ state, action }) => {
    const { payload } = action
    const formViewId = _.get(payload, 'formViewId')
    const currentFilters = _.get(state, `reports.filtersById.${formViewId}`)
    const shouldSetDefault = _.isEmpty(currentFilters)
    return {
      ...state,
      reports: {
        ...state.reports,
        error: undefined,
        status: PENDING,
        resultsById: {
          ...state.reports.resultsById,
          [formViewId]: []
        },
        filtersById: {
          ...state.reports.filtersById,
          [formViewId]: shouldSetDefault ? state.defaultFilters : currentFilters
        }
      }
    }
  }

  fetchReportByFormViewIdsSuccess = ({ state, action }) => {
    const { result, payload } = action
    const reportsState = _.get(state, 'reports', {})
    const formViewId = _.get(payload, 'formViewId')
    const currentResults = _.get(reportsState, 'resultsById', {})
    const currentEntities = _.get(reportsState, 'entities', {})
    const currentFilters = _.get(reportsState, `filtersById`, {})
    const currentFormFilters = _.get(currentFilters, formViewId, {})

    return {
      ...state,
      reports: {
        ...state.reports,
        entities: _.union([...currentEntities, formViewId]),
        resultsById: {
          ...currentResults,
          [formViewId]: result
        },
        filtersById: {
          ...currentFilters,
          [formViewId]: {
            ...currentFormFilters,
            page: 1
          }
        },
        error: undefined,
        status: SUCCESS
      }
    }
  }

  searchReportsNext = promiseReducer(
    (state, action) => {
      const { result, payload } = action
      const formViewId = _.get(payload, 'formViewId')
      const reportsState = _.get(state, 'reports')
      const currentResultsById = _.get(reportsState, 'resultsById', {})
      const currentFormView = _.get(currentResultsById, formViewId)
      const currentResults = _.get(currentFormView, 'results', [])
      const newResults = _.get(result, 'results', [])
      const currentPage = _.get(reportsState, `filtersById.${formViewId}.page`)
      return {
        ...state,
        reports: {
          ...state.reports,
          resultsById: {
            ...currentResultsById,
            [formViewId]: {
              ...currentFormView,
              results: [...currentResults, ...newResults]
            }
          },
          filtersById: {
            ...state.reports.filtersById,
            [formViewId]: {
              ...state.reports.filtersById[formViewId],
              page: currentPage + 1
            }
          },
          error: undefined,
          status: SUCCESS
        }
      }
    }
  )

  fetchReportByFormViewIdsFail = ({ state, action }) => {
    const { error } = action
    return {
      ...state,
      reports: {
        ...state.reports,
        error: error.message,
        status: FAIL
      }
    }
  }

  changeReportFilters = (state, action) => {
    const { filters = {}, payload } = action
    const { formViewId } = payload
    const currentFilters = _.get(state, `reports.filtersById`, {})
    const currentFormFilters = _.get(state, `reports.filtersById.${formViewId}`, {})
    return {
      ...state,
      reports: {
        ...state.reports,
        filtersById: {
          ...currentFilters,
          [formViewId]: {
            ...filters
          }
        }
      }
    }
  }

  changeDefaultFilters = (state, action) => {
    const { filter } = action
    const { defaultFilters } = state
    return {
      ...state,
      defaultFilters: {
        ...defaultFilters,
        [filter.name]: filter.value
      }
    }
  }

  fetchReporters = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.searchReportersPending({ state, action })
      case SUCCESS:
        return this.searchReportersFresh({ state, action })
      case FAIL:
        return this.searchReportersFailed({ state, action })
      default:
        return state
    }
  }

  searchReportersPending = ({ state }) => ({
    ...state,
    reporters: {
      ...state.reporters,
      status: PENDING
    }
  })


  searchReportersFailed = ({ state, action }) => {
    const { error } = action
    return {
      ...state,
      reporters: {
        ...state.reporters,
        error: error.message,
        status: FAIL
      }
    }
  }

  searchReportersFresh = ({ state, action }) => {
    const { result, payload } = action
    const results = _.get(result, 'results', [])
    const currentEntities = _.get(result, 'entities', [])
    const formViewId = _.get(payload, 'formViewId')
    const total = _.get(result, 'total')
    return {
      ...state,
      reporters: {
        ...state.reporters,
        page: 1,
        total,
        entities: _.union([...currentEntities, formViewId]),
        byId: {
          ...state.byId,
          [formViewId]: results
        },
        filtersById: {
          ...state.reporters.filtersById,
          [formViewId]: {
            ...state.reporters.filtersById[formViewId]
          }
        },
        results,
        status: SUCCESS
      }
    }
  }



  searchReportersNext = promiseReducer(
    (state, action) => {
      const { result, payload } = action
      const currentFilters = _.get(state, `reporters.filtersById.${formViewId}`, {})
      const formViewId = _.get(payload, 'formViewId')
      const reportersState = _.get(state, 'reporters')
      const results = _.get(reportersState, 'results', [])
      const page = _.get(state, 'reporters.filtersById.page')
      const newResults = _.get(result, 'results', [])
      const total = _.get(result, 'total')

      return {
        ...state,
        reporters: {
          ...state.reporters,
          page: page + 1,
          total,
          entities: _.map([...results, ...newResults], 'id'),
          byId: this._normalizeResults({ result: [...results, ...newResults] }),
          filtersById: {
            ...state.reporters.filtersById,
            [formViewId]: {
              ...currentFilters,
              page: _.get(currentFilters, 'page', 1) + 1
            }
          },
          results: [...results, ...newResults],
          state: SUCCESS
        }
      }
    }
  )

  changeReportersQuery = (state, action) => {
    const { query, searchType, payload: { formViewId } } = action
    return {
      ...state,
      reporters: {
        ...state.reporters,
        filtersById: {
          ...state.reporters.filtersById,
          [formViewId]: {
            ...state.reporters.filtersById[formViewId],
            query,
            searchType
          }
        }
      }
    }
  }

  changeReportersFilters = (state, action) => {
    const { payload: { formViewId, type, value } } = action
    return {
      ...state,
      reporters: {
        ...state.reporters,
        filtersById: {
          ...state.reporters.filtersById,
          [formViewId]: {
            ...state.reporters.filtersById[formViewId],
            [type]: value
          }
        }
      }
    }
  }

  searchFollowUpFresh = promiseReducer(
    (state, action) => {
      const { payload } = action
      const { formViewId } = payload
      const results = _.get(action, 'result.results', [])
      return {
        ...state,
        followUps: {
          ...state.followUps,
          entities: [...state.followUps.entities, formViewId],
          byId: {
            ...state.followUps.byId,
            [formViewId]: results
          },
          results,
          status: SUCCESS
        }
      }
    }
  )

  updateFollowUpFilters = (state, action) => {
    const { filters, payload } = action
    const { formViewId } = payload
    const currentFilters = _.get(state, `followUps.filtersById.${formViewId}`, {})
    return {
      ...state,
      followUps: {
        ...state.followUps,
        filtersById: {
          ...state.followUps.filtersById,
          [formViewId]: {
            ...currentFilters,
            ...filters
          }
        }
      }
    }
  }

  _normalizeResults = ({ result }) => {
    return _.reduce(result, (memo, item) => {
      memo[item.id] = item
      return memo
    }, {})
  }

  _normalizeFilters = ({ filters = {}, newFilters = {}, formViewId }) => {
    return {
      ...filters,
      [formViewId]: newFilters
    }
  }

  _getFormSchemas = (formViews) => {
    const schemas = {}
    const formSchemaLibrary = new FormSchemaLibrary()

    _.forEach(_.castArray(formViews), (formView) => {
      const formSchema = _.get(formView, 'formSchema', {})
      const formSchemaName = formSchema.name

      if (_.isString(formSchemaName) && !_.get(schemas, formSchemaName)) {
        schemas[formSchemaName] = {
          ...formSchema,
          fields: formSchemaLibrary.getAllFields(formSchemaName)
        }
      }
    })

    return schemas
  }

  setInitialEditorValues = (state, action) => {
    const { payload } = action
    const { category = undefined, name = undefined, template = undefined, type } = payload
    return {
      ...state,
      editor: {
        template,
        category,
        name,
        type
      }
    }
  }

  createFollowUp = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.setFollowUpPending({ state, action })
      case SUCCESS:
        return this.setFollowUpSuccess({ state, action })
      case FAIL:
        return this.setFollowUpFailed({ state, action })
      default:
        return state
    }
  }

  createIndividualFollowUp = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.setFollowUpPending({ state, action })
      case SUCCESS:
        return this.setFollowUpSuccess({ state, action })
      case FAIL:
        return this.setFollowUpFailed({ state, action })
      default:
        return state
    }
  }

  setFollowUpPending = ({ state, action }) => {
    const currentStatus = _.get(state, 'followUps.status', PENDING)
    return {
      ...state,
      followUps: {
        ...state.followUps,
        error: undefined,
        status: currentStatus
      }
    }
  }

  setFollowUpSuccess = ({ state, action }) => {
    return {
      ...state,
      followUps: {
        ...state.followUps,
        status: SUCCESS
      }
    }
  }

  setFollowUpFailed = ({ state, action }) => {
    return {
      ...state,
      followUps: {
        ...state.followUps,
        status: FAIL
      }
    }
  }

  updateFollowUp = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.setFollowUpPending({ state, action })
      case SUCCESS:
        return this.setFollowUpSuccess({ state, action })
      case FAIL:
        return this.setFollowUpFailed({ state, action })
      default:
        return state
    }
  }

  deleteFollowUp = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.setFollowUpPending({ state, action })
      case SUCCESS:
        return this.setFollowUpSuccess({ state, action })
      case FAIL:
        return this.setFollowUpFailed({ state, action })
      default:
        return state
    }
  }

  fetchFollowUpFormView = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.setFollowUpPending({ state, action })
      case SUCCESS:
        return this.fetchFollowUpFormViewSuccess({ state, action })
      case FAIL:
        return this.setFollowUpFailed({ state, action })
      default:
        return state
    }
  }

  fetchFollowUpFormViewSuccess = ({ state, action }) => {
    const clonedState = _.cloneDeep(state)
    const { payload: { followUpId, reportConfigurationId } } = action
    const result = _.get(action, 'result', [])
    const basePath = `followUps.byId.${reportConfigurationId}.followUps`
    const followUpState = _.get(state, basePath)
    const followUpIndex = _.indexOf(_.map(followUpState, ({ id }) => id), followUpId)
    const followUpPath = `${basePath}.${followUpIndex}`
    const currentFollowUpMeta = _.get(state, followUpPath, {})
    _.set(clonedState, followUpPath, { ...currentFollowUpMeta, viewJSON: result })
    _.set(clonedState, 'formViews.entities', _.union([...clonedState.formViews.entities, result.id]))
    _.set(clonedState, 'formViews.byId', { ...clonedState.formViews.byId, [result.id]: result })

    return {
      ...state,
      ...clonedState
    }
  }

  fetchFormViewReporterFollowUps = (state, action) => {
    const { result, payload } = action
    const userId = _.get(payload, 'userId')
    return {
      ...state,
      followUps: {
        ...state.followUps,
        entities: [...state.followUps.entities, userId],
        byReporter: {
          ...state.followUps.byReporter,
          [userId]: result
        },
        result,
        status: SUCCESS
      }
    }
  }

  searchCohortsFresh = (state, action) => {
    const { result, payload } = action
    const formViewId = _.get(payload, 'formViewId')
    return {
      ...state,
      cohorts: {
        ...state.cohorts,
        entities: [...state.cohorts.entities, formViewId],
        byId: {
          ...state.cohorts.byId,
          [formViewId]: result
        },
        result,
        status: SUCCESS
      }
    }
  }

  createCohort = (state, action) => {
    switch (action.status) {
      case PENDING:
      case SUCCESS:
      case FAIL:
        return this.setCohortsStatus({ state, action })
      default:
        return state
    }
  }

  editCohort = (state, action) => {
    switch (action.status) {
      case PENDING:
      case SUCCESS:
      case FAIL:
        return this.setCohortsStatus({ state, action })
      default:
        return state
    }
  }

  deleteCohort = (state, action) => {
    switch (action.status) {
      case PENDING:
      case SUCCESS:
      case FAIL:
        return this.setCohortsStatus({ state, action })
      default:
        return state
    }
  }

  setCohortsStatus = ({ state, action }) => {
    return {
      ...state,
      cohorts: {
        ...state.cohorts,
        error: action.status === PENDING ? undefined : state.cohorts.error,
        status: action.status
      }
    }
  }

  updateFollowUpUser = (state, action) => {
    switch (action.status) {
      case PENDING:
        return this.setFollowUpPending({ state, action })
      case SUCCESS:
        return this.setFollowUpSuccess({ state, action })
      case FAIL:
        return this.setFollowUpFailed({ state, action })
      default:
        return state
    }
  }

  fetchLatestReportVersionForReporter = (state, action) => {
    const { result, payload } = action
    const userId = _.get(payload, 'userId')
    const extReportId = _.get(result, 'extReportId')
    
    return {
      ...state,
      reports: {
        ...state.reports,
        entities: [...state.reports.entities, userId],
        byReporterLatest: {
          ...state.reports.byReporterLatest,
          [userId]: {
            ...state.reports.byReporterLatest[userId],
            [extReportId]: result
          }
        },
        result,
        status: SUCCESS
      }
    }
  }
}

export default new ReportConfigurationReducers()
