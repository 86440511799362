import React, { useEffect } from 'react'
import _ from 'lodash'
import { compose } from 'recompose'

import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { FormControl, Grid, styled } from '@material-ui/core'

import Dropdown from '../../../../components/Fields/Dropdown'
import { withFiltersContainer } from '../../../../components/FiltersContainer'

import {
    hooks as reportConfigurationHooks,
    actions as reportConfigurationActions
} from '../../../../store/modules/reportConfiguration'
import { translations } from '../../../../config'

const Container = styled('div')(({ theme }) => ({
    paddingTop: 20,
    paddingLeft: 10,
    paddingRight: 10,
    maxWidth: 1200,
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('xs')]: {
        paddingLeft: 20,
        paddingRight: 20
    }
}))

const MaxWidthFormControl = styled(FormControl)(({ theme }) => ({
    width: '100%',
    maxWidth: '300px',
    marginLeft: '5px',
    marginRight: '5px'
}))

const userTypeOptions = [
    { value: 'ALL', label: translations('All users') },
    { value: 'STANDARD', label: translations('Registered users') },
    { value: 'GUEST', label: translations('Guest users') }
];

const ReportersSearchFilters = () => {
    const dispatch = useDispatch()
    const { id: formViewId } = useParams()
    const filterValues = reportConfigurationHooks.useReportersFilters({ formViewId })
    const { cohorts, userFilter = 'ALL' } = filterValues
    const cohortOptions = reportConfigurationHooks.useFollowUpCohorts(formViewId)

    const search = _.debounce(() => {
        dispatch(reportConfigurationActions.searchReportersFresh({ formViewId }))
    }, 300)

    const updateFilter = (params) => {
        dispatch(reportConfigurationActions.updateReportersFilters({ ...params, formViewId }))
        search()
    }

    useEffect(() => {
        dispatch(
            reportConfigurationActions.searchFollowUpFresh({ formViewId })
        )
    }, [])

    return (
        <Container>
            <Grid 
                container 
                justifyContent="center"
            >
                    <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <MaxWidthFormControl>
                            <Dropdown
                                label={translations('Cohort(s)')}
                                value={cohorts || null}
                                options={cohortOptions}
                                onChange={(e) => updateFilter({ type: 'cohorts', value: e.target.value })}
                                noErrorTextLabel
                                shrink
                            />
                        </MaxWidthFormControl>
                    </Grid>
                <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <MaxWidthFormControl>
                        <Dropdown
                            label={translations('User type')}
                            value={userFilter}
                            options={userTypeOptions}
                            onChange={(e) => updateFilter({ type: 'userFilter', value: e.target.value })}
                            noErrorTextLabel
                            shrink
                            noNullOption
                        />
                    </MaxWidthFormControl>
                </Grid>
            </Grid>
        </Container>
    )
}

export default compose(
    withFiltersContainer
)(ReportersSearchFilters)
