import React, { useState, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import GlobalStyle from '../GlobalStyles'
import DefaultErrorBoundary, { ErrorInfo } from '../ErrorBoundary'
import { ThemeProvider } from 'styled-components'
import { baseTheme } from '../../styles/themes'
import { SdkProvider } from '../../services/sdk'
import { ReportConfigurationProvider } from '../../services/reportConfigurationContext'
import { FormStatusProvider } from '../../services/formStatusContext'

import ReportForm from '../ReportForm'
import { DiagnosticsContextProvider } from '../../services/diagnostics/context'
import { useDiagnostics } from '../../services/diagnostics/hooks'

/**
 * Generate a vigilance hub form
 */
const VigilanceHubForm = (props) => {
  const {
    formViewId,
    formSchemaId,
    formSchemaName,
    theme,
    onSuccess,
    onError,
    baseUrl,
    organisationId,
    languageCode,
    userDetails,
    initialValues,
    reportTarget,
    source,
    sourceId,
    onSaveDraftSuccess,
    onSaveDraftError,
    enableDrafts,
    enableOfflineDrafts,
    reportId,
    extReportId,
    followUpUserId,
    auth,
    reportLoadingTimeout,
    contactPageLink,
    diagnosticsOptions = null,
    keepDirtyOnReinitialize,
    onFormStateChange,
    singlePageDisplay,
    organisationDetails,
    productDetails,
    readOnly,
    acknowledgementReport,
    isNewReport,
    defaultTranslations = {},
    requestCloseRepeatable
  } = props

  const [isSubmitted, setIsSubmitted] = useState(false)
  const diagnostics = useDiagnostics()
  diagnostics.trackReportMetadata({
    userId: _.get(auth, 'id'),
    extReportId,
    reportId,
    languageCode,
    followUpUserId,
    formViewId
  })

  const isLoggedIn = _.isObject(auth)
  const canEnableDrafts = useMemo(() => {
    const userId = _.get(userDetails, 'id')
    if (enableDrafts && !userId) {
      console.warn('userDetails is missing id, drafts are disabled')
    }
    return (isLoggedIn || enableOfflineDrafts) && enableDrafts && userId    
  }, [isLoggedIn, enableDrafts, userDetails]) 

  const onSuccessHandler = (res, values) => {
    setIsSubmitted(true)
    const [
      extReportId,
      reportId,
      success
    ] = _.at(res, ['report.extReportId', 'report.id', 'success'])
    diagnostics.trackReportMetadata({
      extReportId,
      reportId,
      success
    })
    diagnostics.checkReportJson({ reportJson: values })

    if (typeof onSuccess === 'function') {
      onSuccess({
        response: res,
        reportValues: values
      })
    }
    return res
  }

  const onErrorHandler = (error) => {
    if (typeof onError === 'function') {
      onError({
        code: error.code,
        message: error.message
      })
    }
  }

  if (_.isObject(diagnosticsOptions)) {
    diagnostics.initialise(diagnosticsOptions)
  }

  let ErrorBoundary = DefaultErrorBoundary
  const { isInitialised, hasErrorBoundary } = diagnostics.getStatus()
  if (isInitialised && hasErrorBoundary) {
    const DiagnosticsErrorBoundary = diagnostics.getErrorBoundary()
    ErrorBoundary = DiagnosticsErrorBoundary
  }

  return (
    <ErrorBoundary fallback={ErrorInfo}>
      <DiagnosticsContextProvider>
      <ThemeProvider theme={theme || baseTheme}>
        <GlobalStyle />
        <SdkProvider
          baseUrl={baseUrl}
          organisationId={organisationId}
          formViewId={formViewId}
          languageCode={languageCode}
          user={userDetails}
          source={source}
          sourceId={sourceId}
          auth={auth}
        >
          <div className={'mhraUI'}>
            <ReportConfigurationProvider
              formViewId={formViewId}
              formSchemaId={formSchemaId}
              formSchemaName={formSchemaName}
              followUpUserId={followUpUserId}
              reportLoadingTimeout={reportLoadingTimeout}
              contactPageLink={contactPageLink}
              organisationDetails={organisationDetails}
              productDetails={productDetails}
              defaultTranslations={defaultTranslations}
            >
              <FormStatusProvider>
                {!isSubmitted && (
                  <ReportForm
                    onSuccess={onSuccessHandler}
                    onError={onErrorHandler}
                    onSaveDraftSuccess={onSaveDraftSuccess}
                    onSaveDraftError={onSaveDraftError}
                    userDetails={userDetails}
                    initialValues={initialValues}
                    reportId={reportId}
                    extReportId={extReportId}
                    reportTarget={reportTarget}
                    isLoggedIn={isLoggedIn}
                    enableDrafts={canEnableDrafts}
                    keepDirtyOnReinitialize={canEnableDrafts || keepDirtyOnReinitialize}
                    onFormStateChange={onFormStateChange}
                    singlePageDisplay={singlePageDisplay}
                    readOnly={readOnly}
                    acknowledgementReport={acknowledgementReport}
                    isNewReport={isNewReport}
                    requestCloseRepeatable={requestCloseRepeatable}
                  />
                )}
              </FormStatusProvider>
              {isSubmitted && <p>Report submitted successfully. Redirecting...</p>}
            </ReportConfigurationProvider>
          </div>
        </SdkProvider>
        </ThemeProvider>
      </DiagnosticsContextProvider>
    </ErrorBoundary>
  )
}

VigilanceHubForm.propTypes = {
  /**
  * The report configuration ID created within the Vigilance Hub
  */
  formViewId: PropTypes.string.isRequired,
  /**
  * The URL for the Vigiliance Hub API.
  *
  * Is used for asynchronous services by the Vigilance Hub Form.
  */
  baseUrl: PropTypes.string.isRequired,
  /**
  * The organisation's ID from Vigiliance Hub.
  *
  * Is used with the `baseUrl` for organisation based customisations such as Meddra Dictionary.
  */
  organisationId: PropTypes.string.isRequired,
  /**
   * Callback when form has been successfully submitted
   *
   * An object of { response, reportValues } will be returned
  */
  onSuccess: PropTypes.func,
  /**
   * Callback when form has failed to submit
   *
   * An object of { code, message } will be returned
  */
  onError: PropTypes.func,
  /**
  * The client's chosen language.
  *
  * Is used for the Meddra Dictionary Service.
  */
  languageCode: PropTypes.string,
  /** User details to prepopulate the relevant form fields
   *
   * `id` - Vigilance Hub user Id
   *
   * `email` - The email/username of the user
   *
   * `professionId` - Vigilance Hub Profession Id
   *
   * `firstName` - First name
   *
   * `lastName` - Last name
   *
   * `company` - Company
   *
   * `address` - Address line 1
   *
   * `addressLineTwo` - Address line 2
   *
   * `city` - City
   *
   * `county` - County
   *
   * `postalCode` - Post Code
   * `
   * `telephone` - Telephone
   *
   * `telephoneExtension` - PropTypes.string
  */
  userDetails: PropTypes.shape({
    id: PropTypes.string,
    email: PropTypes.string,
    professionId: PropTypes.string,
    title: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    company: PropTypes.string,
    address: PropTypes.string,
    addressLineTwo: PropTypes.string,
    city: PropTypes.string,
    county: PropTypes.string,
    postalCode: PropTypes.string,
    telephone: PropTypes.string,
    telephoneExtension: PropTypes.string
  }),
  /** Auth payload from /login request. Used to allow the SDK to recreate expired auth tokens.
   *
   * `token` - Successful auth response token
   *
   * `refreshToken` - Successful auth response refresh token
   *
   * `id` - Vigilance Hub user Id
   *
   * `platformId` - Vigilance Hub platform Id
   *
  */
  auth: PropTypes.shape({
    token: PropTypes.string,
    refreshToken: PropTypes.string,
    id: PropTypes.string,
    platformId: PropTypes.string,
  }),
  /**
  * (Advanced) Initial values for the form fields
  *
  * This is an advance feature as it relies on the implementer understanding the underlying form fields
  * of the chosen `formViewId`.
  *
  * Using this could break validation and submission of the report.
  */
  initialValues: PropTypes.object,
  /**
   * The intent of the report i.e the device or drug name
   *
   * For drugs this will add the object into the first drug repeatable and be open by default
   *
   * For devices this will add all the fields to the form data
   */
  reportTarget: PropTypes.object,
  /**
   * Source to associate report
   */
  sourceId: PropTypes.string,
  /**
   * The follow-up the form is being completed for
   */
  followUpUserId: PropTypes.string,
  /**
   * The amount of time(ms) to wait while loading the report form, before showing the `retry` button
   */
  reportLoadingTimeout: PropTypes.number,
  /**
   * contact-us page link
   */
  contactPageLink: PropTypes.string,
  /**
   * Log form events with a diagnostics service.
   */
  diagnosticsOptions: PropTypes.object,
  /**
   * Enable ability to save report as a draft for logged in users
   */
  enableDrafts: PropTypes.bool,
  /**
   * Callback when draft has been successfully saved
   * 
   * An object of { report: { id, extReportId }, success } will be returned
   */  
  onSaveDraftSuccess: PropTypes.func,
  /**
   * Callback when draft has failed to save
   * 
   * An object of { code, message, statusCode } will be returned
  */
  onSaveDraftError: PropTypes.func,
  /**
   * Final Final config (https://final-form.org/docs/final-form/types/Config)
   * 
   * Only pristine values will be overwritten when initialize(newValues) is called.
   * This can be useful for allowing a user to continue to edit a record while the record is being saved asynchronously, and the form is reinitialized to the saved values when the save is successful.
   */
  keepDirtyOnReinitialize: PropTypes.bool,
    /**
   * Callback for when the form state changes
   * 
   * An object of { dirty, pristine, touched, submitting } will be returned
  */
  onFormStateChange: PropTypes.func,
  /**
   * Report details for the report being acknowledged
   */
  acknowledgementReport: PropTypes.object  
}

VigilanceHubForm.defaultProps = {
  languageCode: 'en',
  userDetails: {},
  initialValues: {},
  enableDrafts: false,
  keepDirtyOnReinitialize: false,
  acknowledgementReport: {}
}

export default VigilanceHubForm
