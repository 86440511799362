import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import media from 'styled-media-query'
import _ from 'lodash'
import { usePrevious } from 'react-use'
import { FormSpy } from 'react-final-form'
import { useSdk } from '../../services/sdk/hooks'

import { utilities } from '@redant/mhra-form-schema-library'
import { translation } from '../../services/translations'
import { useFormStatusState } from '../../services/formStatusContext'

import Button from '../Button'
import ErrorMessage from '../ErrorMessage'
import Spinner from '../Spinner'
import ReportFormSection from '../ReportFormSection'
import SectionDisplay from './components/SectionDisplay/SectionDisplay'

const Heading = styled.header`
  padding-top: 15px;
  padding-bottom: 15px;
  ${media.greaterThan('small')`
    padding-top: 30px;
    padding-bottom: 20px;
  `}
`

const FollowUpMessageWrapper = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.lightGray};
  padding: 5px;
`

const FollowUpMessage = styled.p`
  padding: 5px;
  margin: 5px;
  font-size: 13px;
  ${media.greaterThan('medium')`
    font-size: 16px;
    padding: 10px;
  `}
`

const Content = styled.div``

const ErrorSection = styled.div`
  padding: 20px 15px 0;
  text-align: right;
`

const FormActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  ${media.greaterThan('small')`
    padding-top: ${props => `${props.theme.form.spacing.large}px`};
    padding-bottom: ${props => `${props.theme.form.spacing.large}px`};
  `}
`

const HeadingWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  h1 {
    margin-bottom: 0;
  }
`

const RightActions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-direction: row;
`

const ToggleActions = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
`

const ToggleButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border: 0px;
  background-color: white;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 16px;
  ${media.lessThan('medium')`
    width: 100%;
    border: 1px solid black;
    justify-content: center;
  `}
`

/**
 * Turn form sections into a single page display
 */
const FormDisplay = (props) => {
  const {
    formSchemaId,
    schemaName,
    handleSubmit,
    submitError,
    submitting,
    form: {
      batch,
      change,
      submit,
      getFieldState,
      getState
    },
    values,
    organisationDetails,
    professions,
    formViewDetails,
    onSaveDraftSuccess,
    onSaveDraftError,
    reportId,
    followUp,
    enableDrafts,
    formSchemaLibrary,
    onFormStateChange,
    setTouched,
    defaultViewJSON,
    readOnly,
    requestCloseRepeatable
  } = props

  const { saveDraftReport } = useSdk()
  const previousValues = usePrevious(values) || {}
  const [formSections, setFormSections] = useState({})

  const { clearRequiredRepeatableId, requiredRepeatables, openRepeatables } = useFormStatusState()

  useEffect(() => {
    onFormStateChange(getState())
  }, [openRepeatables])

  const isFollowUp = _.has(followUp, 'id')

  const [sectionsOpen, setSectionsOpen] = useState({})
  const [savingDraft, setSavingDraft] = useState(false)

  const [formViewConfig, setFormViewConfig] = useState()
  const audienceId = _.get(formViewConfig, 'audienceId', 1)
  const showNullFlavours = _.get(formViewConfig, 'showNullFlavours', false)

  const getViewJSON = useCallback(() => {
    let viewJSON = defaultViewJSON || {}
    const latestFormViewVersion = _.get(formViewDetails, 'formViewVersions.0.viewJSON')
    if (latestFormViewVersion) {
      viewJSON.sections = latestFormViewVersion
    }
    return viewJSON
  }, [formViewDetails])

  const viewJSON = getViewJSON(formViewDetails)
  const schema = formSchemaLibrary.processSchema(schemaName, viewJSON, translation, { ...formViewConfig, showAllSections: true })
  const changeField = useCallback((_form, field, value) => change(field, value), [change])
  const parseSchema = useCallback(() => {
    return utilities.parseDynamicSchemaSections({ schema, formValues: values, changeField, denyList: [], batchChanges: batch })
      .then((schemaSections) => {
        const parser = utilities.parseDynamicSchema(values, previousValues, changeField, schemaName, false, {}, organisationDetails, formViewConfig, professions, { batchChanges: batch })
        const formSections = {}
        for (const sectionKey in schemaSections) {
          const formSection = schemaSections[sectionKey]
          let formSectionSchema = parser(formSection.schema)
          if (!formSection.hideFromForm && utilities.sectionShouldRender({ fields: formSectionSchema })) {
            formSections[sectionKey] = _.assign({}, formSection, { schema: formSectionSchema })
          }
        }

        return formSections
      })
  }, [schemaName, values, previousValues, organisationDetails, formViewConfig, professions])

  useEffect(() => {
    const formViewConfig = _.get(formViewDetails, 'details', {})
    const audienceId = _.get(formViewConfig, 'audienceId', 1)
    formViewConfig.audienceId = audienceId
    setFormViewConfig(formViewConfig)
  }, [formViewDetails])

  useEffect(() => {
    parseSchema()
      .then((formSections) => {
        setFormSections(formSections)
        const initialOpenSections = _.pickBy(formSections, { initialOpen: true })
        const initialClosedSections = _.pickBy(formSections, { initialOpen: false })
        if (_.isEmpty(sectionsOpen) || _.every(sectionsOpen, (value) => value === false)) {
          const openSections = _.reduce(_.keysIn(initialOpenSections), (memo, section) => {
            return { ...memo, [section]: true }
          }, {})
          const closedSections = _.reduce(_.keysIn(initialClosedSections), (memo, section) => {
            return { ...memo, [section]: false }
          }, {})
          setSectionsOpen({...openSections, ...closedSections})
        }
      })
  }, [values, formViewConfig])

  useEffect(() => {
    _.forEach(requiredRepeatables, (repeatableId) => {
      _.mapKeys(formSections, (value) => {
        const findMatch = _.find(value.schema, (field) => field.id === repeatableId)
        _.get(findMatch, 'props.type') === 'hidden' && clearRequiredRepeatableId(repeatableId)
      })
    })
  }, [formSections, requiredRepeatables])

  if (_.isEmpty(formSections)) {
    return null
  }

  const toggleSection = (index) => {
    const sections = { ...sectionsOpen }
    sections[index] = !(sections[index])
    setSectionsOpen(sections)
  }

  const toggleSections = ({ expand }) => {
    const sections = _.mapValues(sectionsOpen, () => expand);
    setSectionsOpen(sections)
  }

  const onSubmit = (...props) => {
    toggleSections({ expand: true })
    submit(...props)
  }

  const handleFormStateChange = (state) => {
    setTouched(state.touched)
    if (onFormStateChange) {
      onFormStateChange(state)
    }
  }

  const saveDraft = () => {
    setSavingDraft(true)
    return saveDraftReport({ values })
      .then((res) => {
        _.defaultsDeep(res, { report: { id: reportId } })
        onSaveDraftSuccess(res)
      })
      .catch((error) => {
        onSaveDraftError(error, values)
      })
      .finally(() => {
        setSavingDraft(false)
      })
  }

  const renderDraftButton = () => {
    if(readOnly) {
      return (<></>)
    }
    return (
      <Button
        type='button'
        onClick={() => saveDraft()}
        light
        style={{ marginRight: 10 }}
        loading={savingDraft}
      >
        {translation('Save draft')}
      </Button>
    )
  }

  const renderActions = () => {
    if(readOnly) {
      return (<></>)
    }
    if (submitting) {
      return (
        <Spinner />
      )
    }
    return (
      <>
        <RightActions>
          {enableDrafts && renderDraftButton()}
          {<Button type='button' onClick={onSubmit}>Submit</Button>}
        </RightActions>
      </>
    )
  }

  const renderFormSections = () => {
    const numberOfSections = _.size(formSections)
    let position = 1;
    return _.map(formSections, (section, index) => {
      const { title, schema } = section
      return (
        <SectionDisplay
          title={title}
          isOpen={sectionsOpen[index]}
          toggle={() => toggleSection(index)}
          index={position++}
          numberOfSections={numberOfSections}
        >
          <ReportFormSection
            schema={schema}
            change={change}
            getFieldState={getFieldState}
            getState={getState}
            audienceId={audienceId}
            schemaName={schemaName}
            showNullFlavours={showNullFlavours}
            useSupportedFieldTypes={false}
            readOnly={readOnly}
            requestCloseRepeatable={requestCloseRepeatable}
          />
        </SectionDisplay>
      )
    })
  }

  return (
    <Content>
      <Heading>
        <HeadingWrap>
          <ToggleActions>
            <ToggleButton onClick={() => toggleSections({ expand: true })}>Show all sections</ToggleButton>
            <ToggleButton onClick={() => toggleSections({ expand: false })}>Hide all sections</ToggleButton>
          </ToggleActions>
        </HeadingWrap>
        {isFollowUp && (
          <FollowUpMessageWrapper>
            <FollowUpMessage>{translation(_.get(followUp, 'followUp.message'))}</FollowUpMessage>
          </FollowUpMessageWrapper>
        )}
      </Heading>
      <form onSubmit={handleSubmit}>
        {renderFormSections()}
        {submitError && <ErrorSection>
          <ErrorMessage error={submitError} />
        </ErrorSection>}
        <FormActions>
          {renderActions()}
        </FormActions>
      </form>
      <FormSpy
        subscription={{
          values: true,
          submitting: true,
          submitError: true,
          submitFailed: true,
          dirty: true,
          touched: true,
        }}
        onChange={handleFormStateChange}
      />
    </Content>
  )
}

FormDisplay.propTypes = {
  /**
   * Wraps the submit function - passed from React Final Form
   */
  handleSubmit: PropTypes.func.isRequired,
  /**
   * Sets to true when the form is submitting - passed from React Final Form
   */
  submitting: PropTypes.bool,
  /**
   * Function to call when the wizard should go back a step
   */
  onPrevious: PropTypes.func.isRequired,
  /**
   * Callback function when the wizard has parsed the schema and knows the total step count
   */
  onStepCount: PropTypes.func,
  /**
   * The current step
   */
  index: PropTypes.number,
  /** [React Final Form Instance API](https://final-form.org/docs/final-form/types/FormApi)
   *
   * `change` - Changes a field (used by parseDynamicSchema)
   *
   * `submit` - Trigger form submit (also handles {translation('next step')})
  */
  form: PropTypes.shape({
    change: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired
  }).isRequired,
  /**
   * Current form values
   */
  values: PropTypes.object,
  /**
   * Vigilance Hub organisation details
   */
  organisationDetails: PropTypes.object,
  /**
   * Vigilance Hub organisation's professions
   */
  professions: PropTypes.array,
  /**
   * FormView configuration
   */
  formViewDetails: PropTypes.object,
  /**
   * The default form schema name to use.
   *
   * Primarily used for testing where a `formViewId` has not been setup
   */
  schemaName: PropTypes.string,
  /**
   * FollowUp details
   */
  FollowUp: PropTypes.object,
  /**
   * Enable ability to save report as a draft for logged in users
   */
  enableDrafts: PropTypes.bool
}

FormDisplay.defaultProps = {
  values: {},
  organisationDetails: {},
  professions: [],
  formViewDetails: {},
  index: 0,
  submitting: false,
  enableDrafts: false
}

export default FormDisplay
