import React, { useState } from 'react'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { styled, Tabs, Tab, AppBar } from '@material-ui/core'
import SearchInput from '../../../../components/SearchInput'
import { translations } from '../../../../config'
import {
    hooks as reportConfigurationHooks,
    actions as reportConfigurationActions
} from '../../../../store/modules/reportConfiguration'

const InputContainer = styled('div')(({ theme }) => ({
    width: '100%',
    margin: '0 auto 10px auto',
    [theme.breakpoints.up('md')]: {
        width: '50%'
    }
}))

const TabBar = styled(AppBar)(({ theme }) => ({
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    marginBottom: theme.spacing(2)
}))

const StyledTabs = styled(Tabs)({
    minHeight: 48,
    '& .MuiTabs-flexContainer': {
        justifyContent: 'center'
    }
})

const StyledTab = styled(Tab)({
    minWidth: 120,
    minHeight: 48,
    textTransform: 'none'
})

const ReportersSearch = ({ formViewId }) => {
    const dispatch = useDispatch()
    const searchQuery = reportConfigurationHooks.useReportersQuery({ formViewId })
    const [searchValue, setSearchValue] = useState(searchQuery)
    const [searchType, setSearchType] = useState('name')

    const search = _.debounce(() => {
        dispatch(reportConfigurationActions.searchReportersFresh({ formViewId }))
    }, 300)

    const updateQuery = (e) => {
        const query = e.target.value
        setSearchValue(query)
        dispatch(reportConfigurationActions.changeReportersQuery({ formViewId, query, searchType }))
        search()
    }

    const clearQuery = () => {
        setSearchValue('')
        dispatch(reportConfigurationActions.changeReportersQuery({ formViewId, query: '', searchType }))
        search()
    }

    const handleTabChange = (event, newValue) => {
        setSearchType(newValue)
        setSearchValue('')
        dispatch(reportConfigurationActions.changeReportersQuery({ formViewId, query: '', searchType: newValue }))
        search()
    }

    return (
        <InputContainer>
            <TabBar position="static">
                <StyledTabs
                    value={searchType}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                >
                    <StyledTab
                        value="name"
                        label={translations('Report Configuration - Search by Name')}
                    />
                    <StyledTab
                        value="reportId"
                        label={translations('Report Configuration - Search by Report ID')}
                    />
                </StyledTabs>
            </TabBar>
            <SearchInput
                value={searchValue}
                placeholder={searchType === 'name'
                    ? translations('Report Configuration - Search by Name')
                    : translations('Report Configuration - Search by Report ID')}
                onChange={(e) => updateQuery(e)}
                onClear={() => clearQuery()}
                autoFocus={false}
            />
        </InputContainer>
    )
}

export default ReportersSearch
