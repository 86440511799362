import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withStyles } from '@material-ui/core/styles'

import BackBar from '../../../../components/BackBar'
import SubHeader from '../../../../components/SubHeader'
import VisuallyHidden from '../../../../components/VisuallyHidden'
import LoadingBar from '../../../../components/LoadingBar'
import P from '../../../../components/P'
import DataTable from '../../../../components/DataTable'
import DataTableSkeleton from '../../../../components/DataTable/DataTableSkeleton'

import SubmissionReportsSearchInput from '../SubmissionReportsSearchInput'
import SubmissionReportsSearchFilters from '../SubmissionReportsSearchFilters'
import SubmissionReportsRow from '../SubmissionReportsRow'
import ListViewLoadMore from '../../../../components/ListViewLoadMore'
import SubmissionReportsToolbar from '../SubmissionReportsToolbar'

import { translations } from '../../../../config'
import styles from './style'
import ReportSummaryModal from '../../../ReportManagement/ReportsSearchScreen/NewReportSummaryModal'
import { addQuery, removeQuery } from '../../../../helpers/query'
import { useInitialFetch, useQuery } from '../../../../hooks'
import { actions as sourceActions } from '../../../../store/modules/sources'
import { selectors as authSelectors } from '../../../../store/modules/auth'

// eslint-disable-next-line react/prop-types
const renderResults = ({ classes, headers, data, isLoading, hasResults = false, isEmpty = false, canLoadMore = false, loadNext, count, total, updateFilter, rowMoreOptions }) => {
  if (isLoading) {
    return <DataTableSkeleton colCount={headers.length} rowCount={10} showHeader />
  }

  if (!hasResults) {
    return (
      <div className={classes.message}>
        <P role='status' value={translations('No results found')} />
      </div>
    )
  }

  return (
    <Fragment>
      <DataTable
        headers={headers}
        data={data}
        tableId={'case-management-table'}
        tableHeading={'Cases'}
        count={count}
        total={total}
        rowComponent={SubmissionReportsRow}
        rowProps={{ updateFilter, rowMoreOptions }}
        toolbarComponent={SubmissionReportsToolbar}
        toolbarProps={{
          navId: 'case-management-toolbar',
          navHeading: translations('Case Management - Toolbar heading')
        }}
      />
      {canLoadMore && <ListViewLoadMore onClick={() => loadNext()} isLoading={isLoading} />}
    </Fragment>
  )
}

const CaseSearchScreen = (props) => {
  const {
    classes,
    pageTitle,
    isLoading,
    headers = [],
    data = [],
    hasResults,
    canLoadMore,
    loadNext,
    count,
    total,
    updateFilter,
    rowMoreOptions,
    fetchSavedSearches
  } = props

  const { reportId } = useQuery()
  const organisationId = useSelector(authSelectors.getUserSelectedOrganisationId)

  useInitialFetch(fetchSavedSearches)
  useInitialFetch(() => sourceActions.fetchOrganisationSources({ organisationId }))

  const openReportSummaryModal = ({ reportId }) => {
    addQuery({ reportId })
  }

  const handleClose = () => {
    removeQuery(['reportId'])
  }

  return (
    <div className={classes.container}>
      <VisuallyHidden>
        <h1>{pageTitle.title}</h1>
      </VisuallyHidden>
      <Helmet>
        <title>{pageTitle.titleWithName}</title>
      </Helmet>
      <SubHeader
        leftContent={(
          <BackBar />
        )}
        centerContent={(
          <SubmissionReportsSearchInput />
        )}
      />
      <div className={classes.content}>
        <LoadingBar isLoading={isLoading} />
        <SubmissionReportsSearchFilters isLoading={isLoading} />
        {renderResults({
          classes,
          headers,
          data,
          hasResults,
          isLoading,
          canLoadMore,
          loadNext,
          count,
          total,
          updateFilter,
          rowMoreOptions: { ...rowMoreOptions, showReportSummary: openReportSummaryModal }
        })}
      </div>
      <ReportSummaryModal
        isOpen={!!reportId}
        handleClose={handleClose}
        reportId={reportId}
      />
    </div>
  )
}

CaseSearchScreen.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  pageTitle: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleWithName: PropTypes.string
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasResults: PropTypes.bool,
  canLoadMore: PropTypes.bool,
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  loadNext: PropTypes.func.isRequired,
  count: PropTypes.number,
  total: PropTypes.number,
  updateFilter: PropTypes.func.isRequired,
  rowMoreOptions: PropTypes.object.isRequired
}

export default withStyles(styles)(CaseSearchScreen)
